<template>
  <v-app >
    <Navigation/>
    <v-main class="home">
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0">
        <router-view></router-view>
        <div
          class="content"
          transition="scroll-y-reverse-transition"
          :style="{borderTopColor:theme.primaryColor} ">
          <div class="content__header">
            <h1 class="content__header--title float-left" >
              Tus beneficios
            </h1>
          </div>
          <hr class="content__divider"/>

          <div class="service row">
            <v-row>
              <div
                class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2"
                v-for="item in rewards"
                :key="item.id"
              >
                  <ServiceItem
                    :title="item.attributes.title"
                    :description="item.attributes.description"
                    :date="'Hasta el '+item.attributes['expiration']"
                    :theme="theme"
                    :img="item.attributes.images != undefined ? item.attributes.images[0].image.thumb.url : '//via.placeholder.com/300x200'"
                    :service_id="item.id.toString()"
                    button_text="beneficio"
                  />
              </div>
            </v-row>
          </div>

        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import Logo from '../assets/img/logo.png'

  export default {
    name: 'Benefits',
    data () {
      return {
        is_changed: false,
        rewards: [],
        Logo: Logo,
        theme:{
          primaryColor: '#E33C76',
          secundaryColor: '#e9749c',
          service: 'benefits'
        },
        hoverTabs: false,
        showCategory: true,
        months: ['Enero', 'Febrero', 'Marzo', 'Abril'],

        items: [
        {
          id: 1,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          category: 'Ciencia y Tecnología',
          img: ''
        },
        {
          id: 2,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          category: 'Tecnología',
          img: ''
        },
        {
          id: 3,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          img: ''
        },
        {
          id: 4,
          title: 'Título de la tarjeta con 2 renglones máximo',
          description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
          date: '7 de julio de 2021',
          img: ''
        }
      ]
      }
    },
    components: {
    },
    mounted(){
      this.checkDevice();
      this.send_request();
    },
    methods:{
      send_request(){
        // var flag = false;
        // var flag2 = false;
        var vm = this;
        try{
          vm.$http.get("app/offers/rewards", {
            headers:
            this.getUserToken() != '' ?
            {
              "Authorization": "Bearer " + this.getUserToken(),
              "X-Device-ID" : this.buildDeviceId(),
              "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
            } :
            {
              "X-Device-ID" : this.buildDeviceId(),
              "Geolocation" : "lat: " + this.getLatitude() + ", long: " + this.getLongitude()
            }
          }).then(function(response){
            for (var i = 0; i < response.body.data.length; i++) {
              var temp_obj = response.body.data[i]
              temp_obj.attributes.is_favorite = false
              for (var j = 0; j < response.body.meta.favorites.length; j++) {
                if(response.body.meta.favorites[j].favoritable_id == temp_obj.id){
                  temp_obj.attributes.is_favorite = true
                }
              }
              console.log(temp_obj);
              this.rewards.push(temp_obj)
            }
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
          }, function(response){
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
            console.log("Respuesta error de rewards");
            if (response.status==401) {
              // this.show_error_modal(response.body.errors[0].details, "Un momento...");
              this.show_error_modal("Tienes una sesión abierta en otro dispositivo.", "Un momento...");
              this.deleteSession('login', true)
            }else{
              this.show_error_modal(response.body.errors[0].details);
            }
          });
        }catch(e){
          console.log("Fallo rewards");
          this.show_error_modal(e.message);
        }

        vm.is_changed = false;
      },
    }
  }
</script>

<style scoped>
  .buttom_tabs--active{
    background: #F44336 0% 0% no-repeat padding-box !important;
    color: #FFF !important;
  }
  .buttom_tabs:hover{
    background: #F44336 0% 0% no-repeat padding-box !important;
    color: #FFF !important;
  }
</style>
